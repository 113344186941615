<!-- =========================================================================================
  File Name: InboxWhatsappTemplates.vue
  Description: Inbox whatsapp api templates
========================================================================================== -->

<template>
  <div>
    <div class="flex flex-wrap items-center justify-between">
      <div class="w-auto flex">
        <vs-select v-model="chatFilter" class="mr-4">
          <vs-select-item
            v-for="(item, index) in chatOptions"
            :key="index"
            :value="item"
            :text="item.name"
          />
        </vs-select>
        <vs-input
          class="flex-none sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          icon="icon-search"
          icon-pack="feather"
          v-model="searchQuery"
          @input="updateSearchQuery"
          :placeholder="$t('Search')"
        />
      </div>
      <div class="flex my-1">
        <vs-button
          v-if="
            this.$store.state.chat.whatsappApi360DialogCurrentChat &&
            this.$store.state.chat.whatsappApi360DialogCurrentChat.inboxConfig
              .wppApi360DialogCloudApi
          "
          icon-pack="feather"
          icon="icon-upload-cloud"
          size="medium"
          class="mr-4 md:mb-0 text-sm"
          @click="resendMediaTemplates()"
          >Reenviar templates</vs-button
        >
        <vs-button
          icon-pack="feather"
          icon="icon-refresh-cw"
          size="medium"
          class="mr-4 md:mb-0 text-sm"
          color="primary"
          @click="load360DialogTemplates()"
          :disabled="syncingTemplates"
          >{{
            syncingTemplates ? $t("SyncingTemplates") : $t("SyncTemplates")
          }}</vs-button
        >
        <vs-button
          icon-pack="feather"
          icon="icon-file-text"
          size="medium"
          class="mb-4 md:mb-0 text-sm"
          color="success"
          @click="popupActive = true"
          >{{ $t("AddTemplate") }}</vs-button
        >
      </div>
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      @gridReady="onGridReady"
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="whatsappApi360DialogTemplates"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
      :overlayNoRowsTemplate="noRowsTemplate"
      id="quick-messages-table"
    />

    <vs-popup title="Template Webhook" :active.sync="popupWebhookActivate">
      <div class="w-full my-2 mr-2" v-if="template && template.name">
        <input type="hidden" :value="template.name" id="templateName2" />
        <vs-input
          icon-after="true"
          disabled="disabled"
          icon="icon-copy"
          icon-pack="feather"
          label-placeholder="Template ID"
          v-model="template.name"
          id="templateName"
          v-on:icon-click="copyInputText('templateName')"
        />
      </div>
      <div class="w-full mt-4">
        <small>{{ $t("Intention") }}</small>
        <v-select
          v-model="intentionWebhook"
          :clearable="false"
          :options="intentionsOptions"
        />
      </div>
      <div class="w-full mt-4" v-if="status === 'approved'">
        Webhook
        <div class="flex flex-wrap mt-2">
          <vs-radio class="mr-2 mt-2" :vs-value="1" v-model="webhookChannel"
            >RD Station</vs-radio
          >
          <vs-radio class="mr-2 mt-2" :vs-value="2" v-model="webhookChannel"
            >ActiveCampaign</vs-radio
          >
          <vs-radio class="mr-2 mt-2" :vs-value="5" v-model="webhookChannel"
            >Nectar CRM</vs-radio
          >
          <vs-radio class="mr-2 mt-2" :vs-value="6" v-model="webhookChannel"
            >Followize</vs-radio
          >
          <vs-radio class="mr-2 mt-2" :vs-value="7" v-model="webhookChannel"
            >CRM Educacional</vs-radio
          >
          <vs-radio class="mr-2 mt-2" :vs-value="3" v-model="webhookChannel"
            >Custom</vs-radio
          >
          <vs-input
            v-if="webhookChannel === 3"
            class="mr-2 my-2"
            v-model="webhookChannelName"
            placeholder="Custom"
          />
        </div>
        <div class="w-full my-4 mr-2">
          <p>Enviar para conversas em aberto?</p>
          <vs-radio v-model="sendToOpenConversations" vs-value="1" class="mr-3">
            {{ $t("Yes") }}
          </vs-radio>
          <vs-radio v-model="sendToOpenConversations" vs-value="2">
            {{ $t("No") }}
          </vs-radio>
        </div>
        <div class="h-5"></div>
        <div class="w-full my-4" v-if="webhookChannel">
          <vs-input
            class="w-full"
            v-model="webhookURL"
            icon-after="true"
            icon="icon-copy"
            icon-pack="feather"
            label-placeholder="Webhook URL"
            id="webhookURL"
            v-on:icon-click="copyInputText('webhookURL')"
          />
        </div>
      </div>
      <div v-else>
        <h2>Template não aprovado.</h2>
      </div>
    </vs-popup>

    <vs-popup title="Template" :active.sync="popupActive">
      <vs-row>
        <vs-col vs-w="12">
          <vs-input
            name="email"
            v-model="title"
            type="email"
            class="w-full"
            :label="$t('Title') + '*'"
          />
        </vs-col>
        <vs-col vs-w="12" class="mt-4">
          <div
            class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
          >
            <label class="vs-input--label">{{ $t("Intention") }}</label>
            <v-select
              v-model="intentions"
              :options="intentionsOptions"
              multiple
            />
          </div>
        </vs-col>
        <vs-col vs-w="12" class="mt-4">
          <div
            class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
          >
            <label class="vs-input--label">{{ $t("KeyEntities") }}</label>
            <v-select v-model="entities" :options="inboxEntities" multiple />
          </div>
        </vs-col>
        <vs-col vs-w="12" class="mt-4 grid grid-cols-2 gap-x-3">
          <vs-select v-model="category" :label="$t('Category')" width="100%">
            <vs-select-item
              v-for="(item, index) in wppApiCategories"
              :key="index"
              :value="item"
              :text="item"
            />
          </vs-select>
          <vs-select v-model="language" :label="$t('Language')" width="100%">
            <vs-select-item
              v-for="(item, index) in languages"
              :key="index"
              :value="item.value"
              :text="item.label"
            />
          </vs-select>
        </vs-col>
        <vs-col vs-w="12" class="mt-4">
          <vs-select v-model="headerType" :label="$t('Header')" width="100%">
            <vs-select-item
              v-for="(item, index) in headerTypes"
              :key="index"
              :value="item"
              :text="item"
            />
          </vs-select>
        </vs-col>
        <vs-col vs-w="12" class="mt-4" v-if="headerType !== 'Nenhum'">
          <input
            v-if="headerType !== 'Nenhum'"
            class="my-3"
            type="file"
            @change="previewFile"
            ref="headerInputMedia"
            :accept="headerMimes"
          />
          <img
            v-if="headerType === 'IMAGE' && headerMedia"
            :src="headerUrl"
            class="w-full"
          />
          <video
            v-if="headerType === 'VIDEO' && headerMedia"
            controls="controls"
          >
            <source :src="headerUrl" />
            <!--Suportado em IE9, Chrome 6 e Safari 5 -->
            O seu navegador não suporta a tag vídeo
          </video>
        </vs-col>
        <vs-col
          vs-w="12"
          class="mt-4"
          v-if="accHasAIFeature"
          vs-type="flex"
          vs-align="center"
          vs-justify="space-between"
        >
          <vs-input
            v-model="chatGptSuggestionText"
            type="text"
            color="warning"
            :placeholder="$t('ChatGptSuggestionTemplatePlaceholder')"
            style="width: calc(100% - 219px)"
          />
          <vs-button
            class="px-2"
            @click="generateChatGptSuggestion()"
            :disabled="!this.chatGptSuggestionText.length"
            :icon="!this.chatGptSuggestionGenerated ? 'electric_bolt' : 'sync'"
            color="warning"
            type="border"
          >
            {{
              this.chatGptSuggestionGenerated
                ? "Refazer"
                : "Sugerir um template"
            }}
          </vs-button>
        </vs-col>
        <vs-col vs-w="12" class="mt-4">
          <p class="vs-select--label">{{ $t("Body") }}*</p>
          <vs-textarea
            name="body"
            v-model="body"
            placeholder="Escreva Sua Mensagem..."
            rows="5"
          />
          <small>
            * Digite {NOME_ATENDENTE} para adicionar o nome do atendente,
            {NOME_CONTATO} para o nome do contato e {ID_CONVERSA} para adicionar
            o id da conversa.
            <a
              href="https://ajuda.duotalk.io/pt/articles/52-enviar-mensagens-com-variaveis"
              target="_blank"
              class="underline"
              >Saiba mais</a
            >
            sobre como utilizar outras variáveis customizáveis nas mensagens
            templates
          </small>
        </vs-col>
        <vs-col vs-w="12" class="mt-4">
          <h5>{{ $t("Buttons") }}:</h5>
        </vs-col>
        <vs-col vs-w="12" class="mt-3 grid grid-cols-3 gap-x-3 items-end">
          <vs-select
            v-model="buttonType"
            :label="$t('Type')"
            width="100%"
            class="col-span-2"
          >
            <vs-select-item
              v-for="(item, index) in buttonTypes"
              :key="index"
              :value="item"
              :text="item"
              :disabled="buttons.length > 0"
            />
          </vs-select>
          <vs-button
            color="success"
            @click="addButton"
            icon-pack="feather"
            icon="icon-plus"
            size="small"
            style="height: 38px"
            >{{ $t("AddButton") }}
          </vs-button>
        </vs-col>
        <vs-col vs-w="12" class="mt-4">
          <Inbox360DialogTemplatesButton
            v-for="(button, i) in buttons"
            :key="i"
            :index="i"
            :button="button"
            :buttonType="buttonType"
            @remove="removeButton(i)"
          />
        </vs-col>
        <vs-checkbox v-model="optOutAllowed" size="small">
          <p class="text-grey text-sm">
            Permitir Opt-Out no rodapé do template.
          </p>
        </vs-checkbox>
        <vs-col vs-w="12" class="mt-4">
          <vs-button
            class="float-right"
            icon-pack="feather"
            icon="icon-save"
            @click="createOrUpdateTemplate()"
            >{{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup :title="$t('Edit')" :active.sync="popupEditActive">
      <div class="flex items-center justify-center warning mb-2">
        <p class="font-medium">
          <span class="mr-1 font-bold">Aviso:</span>Você não pode editar
          templates depois de criados, mas pode editar as
          <span>entidades chave</span> e <span>intenções</span>.
        </p>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="w-full">
            <div class="flex">
              <p
                style="padding: 5px; margin: 2px 0"
                class="uppercase rounded-lg"
                :class="statusStyle"
              >
                {{ status }}
                <!-- {{ status === 'APPROVED' ? 'Aprovado' : (status === 'REJECTED' ? 'Rejeitado' : '')}} -->
              </p>
            </div>
            <div class="flex mb-1 align-middle">
              <vs-input
                name="email"
                v-model="title"
                type="email"
                class="w-full my-3"
                :label="$t('Title') + '*'"
              />
            </div>

            <small>{{ $t("Intention") }}</small>
            <v-select
              id="intentions"
              v-model="intentions"
              :clearable="false"
              :options="intentionsOptions"
              multiple
              name="intentions"
              class="w-full"
            />

            <small>{{ $t("KeyEntities") }}</small>
            <v-select
              id="entities"
              v-model="entities"
              :clearable="false"
              :options="inboxEntities"
              multiple
              name="entities"
              class="w-full"
            />

            <div class="flex mt-3">
              <div class="w-full mr-1">
                <small>{{ $t("Category") }}*</small>
                <v-select
                  id="category"
                  v-model="category"
                  :clearable="false"
                  disabled
                  :options="wppApiCategories"
                  name="category"
                />
              </div>

              <div class="w-full mb-3">
                <small>{{ $t("Language") }}*</small>
                <v-select
                  id="language"
                  v-model="language"
                  :clearable="false"
                  disabled
                  :options="languages"
                  name="language"
                />
              </div>
            </div>

            <div class="flex flex-col align-middle">
              <small class="mb-1">Header</small>
              <v-select
                id="headerType"
                v-model="headerType"
                :clearable="false"
                :options="headerTypes"
                name="headerType"
                disabled
              />
              <input
                v-if="headerType !== 'Nenhum'"
                class="my-3"
                type="file"
                @change="previewFile"
                ref="headerInputMedia"
                :accept="headerMimes"
              />
              <img
                v-if="headerType === 'IMAGE' && headerMedia"
                :src="headerUrl"
                width="50%"
                height="50%"
              />

              <video
                v-if="headerType === 'VIDEO' && headerMedia"
                controls="controls"
                width="50%"
                height="50%"
              >
                <source :src="headerUrl" />
                <!--Suportado em IE9, Chrome 6 e Safari 5 -->
                O seu navegador não suporta a tag vídeo
              </video>
            </div>

            <small>Body*</small>
            <vs-textarea
              name="body"
              v-model="body"
              placeholder="Escreva Sua Mensagem..."
              rows="6"
              disabled
            >
            </vs-textarea>

            <div class="mt-5 flex flex-col">
              <h5 class="mb-1 mr-2">{{ $t("Buttons") }}:</h5>
              <small class="mb-1 mr-2">{{ $t("Type") }}</small>
              <div class="flex">
                <v-select
                  id="buttonType"
                  v-model="buttonType"
                  :clearable="false"
                  :options="buttonTypes"
                  name="buttonType"
                  class="flex-1 mr-1"
                  disabled
                />
                <vs-button
                  size="medium"
                  class="md:mb-0 text-sm"
                  color="success"
                  disabled
                  >{{ $t("AddButton") }}</vs-button
                >
              </div>
              <Inbox360DialogTemplatesButton
                v-for="(button, i) in buttons"
                :key="i"
                :index="i"
                :button="button"
                :buttonType="buttonType"
                @remove="removeButton(i)"
                :disabled="true"
              />
            </div>
          </div>

          <vs-checkbox
            v-model="optOutAllowed"
            size="small"
            class="mt-4"
            disabled
          >
            <p class="text-grey text-sm">
              Permitir Opt-Out no rodapé do template.
            </p>
          </vs-checkbox>

          <vs-button
            icon-pack="feather"
            icon="icon-file-plus"
            size="medium"
            class="mt-4 mb-4 md:mb-0"
            @click="createOrUpdateTemplate()"
            >{{ $t("Save") }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";

import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

import CellRendererStatus360Dialog from "./cell-render-whatsapp-api-360-dialog-templates/CellRendererStatus360Dialog.vue";
import CellRendererMediaStatus360Dialog from "./cell-render-whatsapp-api-360-dialog-templates/CellRendererMediaStatus360Dialog.vue";
import CellRendererActions360Dialog from "./cell-render-whatsapp-api-360-dialog-templates/CellRendererActions360Dialog.vue";
import Inbox360DialogTemplatesButton from "./Inbox360DialogTemplatesButton.vue";
import { LANGUAGES } from "@/helpers/whatsappApiLanguageSuport";

import vSelect from "vue-select";
import normalizeString from "@/helpers/normalizeString";

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    CellRendererStatus360Dialog,
    CellRendererActions360Dialog,
    Inbox360DialogTemplatesButton,
    CellRendererMediaStatus360Dialog,
    "v-select": vSelect,
  },
  data() {
    return {
      buttons: [],
      buttonType: "QUICK_REPLY",
      buttonTypes: ["QUICK_REPLY", "CALL_TO_ACTION"],
      popupWebhookActivate: false,
      webhookChannel: null,
      webhookChannelName: "",
      ia: {},
      intentionWebhook: null,
      submitingWhatsappApiTemplate: false,
      currentQuickMessageId: null,
      showAllWppTemplates: false,
      popupWhatsappActive: false,
      allWppTemplates: [],
      wppTemplateCategory: null,
      currentWppTemplate: null,
      quickMessage: null,
      wppTemplate: {
        category: null,
        name: "",
        language: "pt_BR",
        components: [
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: "",
              },
            ],
          },
        ],
      },
      // end wpp data
      popupEditActive: false,
      inboxID: null,
      editid: null,
      inboxIntentions: [],
      acl: "",
      aclOptions: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: this.$t("Manager"),
          value: "manager",
        },
        {
          label: this.$t("Operator"),
          value: "operator",
        },
      ],
      popupActive: false,
      searchQuery: "",
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this,
        },
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$t("Title"),
          field: "title",
          filter: true,
          getQuickFilterText: (params) => {
            return normalizeString(params.value);
          },
        },
        {
          headerName: this.$t("Category"),
          field: "category",
          filter: true,
          getQuickFilterText: (params) => {
            return normalizeString(params.value);
          },
        },
        {
          headerName: this.$t("Message"),
          field: "body",
          filter: true,
          getQuickFilterText: (params) => {
            return normalizeString(params.value);
          },
        },
        {
          headerName: "Header",
          field: "header",
          filter: true,
          cellRenderer: "CellRendererMediaStatus360Dialog",
        },
        {
          headerName: this.$t("Intentions"),
          field: "intentions",
          filter: true,
          valueFormatter: this.replaceEntitiesIntentions,
          getQuickFilterText: (params) => {
            return normalizeString(params.value[0]);
          },
        },
        {
          headerName: this.$t("KeyEntities"),
          field: "entities",
          filter: true,
          valueFormatter: this.replaceEntitiesIntentions,
          getQuickFilterText: (params) => {
            return normalizeString(params.value[0]);
          },
        },
        {
          headerName: this.$t("Status"),
          field: "status",
          filter: true,
          cellRenderer: "CellRendererStatus360Dialog",
        },
        {
          headerName: this.$t("CardActions"),
          field: "_id",
          cellRenderer: "CellRendererActions360Dialog",
          maxWidth: 180,
        },
      ],
      inboxQuickMessagesFiltered: [],
      wppApiCategories: ["AUTHENTICATION", "UTILITY", "MARKETING"],
      templateID: null,
      template: null,
      sendToOpenConversations: "2",
      title: "",
      name: null,
      status: null,
      intentionsOptions: [],
      intentions: [],
      inboxEntities: [],
      entities: [],
      body: "",
      headerMedia: null,
      headerType: "Nenhum",
      headerTypes: ["Nenhum", "IMAGE", "VIDEO", "DOCUMENT"],
      category: "UTILITY",
      language: "pt_BR",
      languages: LANGUAGES,
      currentOperator: null,
      operators: null,
      chatGptSuggestionGenerated: false,
      chatGptSuggestionText: "",
      syncingTemplates: true,
      noRowsTemplate: `<span>Sem dados no momento</span`,
      optOutAllowed: false,
    };
  },
  watch: {
    popupWebhookActivate(val) {
      if (!val) {
        this.templateID = null;
        this.template = null;
        this.status = null;
        this.intentionWebhook = null;
        this.webhookChannel = null;
        this.webhookChannelName = null;
      }
    },
    headerType() {
      if (this.templateID) return;
      this.headerMedia = null;
      if (this.$refs.headerInputMedia) this.$refs.headerInputMedia.value = "";
    },
    popupEditActive(val) {
      if (!val) {
        if (this.$refs.headerInputMedia) this.$refs.headerInputMedia.value = "";
        this.headerType = "Nenhum";
        this.headerMedia = null;
        this.name = null;
        this.templateID = null;
        this.title = "";
        this.status = null;
        this.category = "UTILITY";
        this.language = "pt_BR";
        this.body = "";
        this.intentions = [];
        this.entities = [];
        this.buttons = [];
        this.optOutAllowed = false;
        this.buttonType = this.buttonTypes[0];
      }
    },
    popupActive(val) {
      if (!val) {
        if (this.$refs.headerInputMedia) this.$refs.headerInputMedia.value = "";
        this.headerType = "Nenhum";
        this.headerMedia = null;
        this.name = null;
        this.templateID = null;
        this.title = "";
        this.status = null;
        this.category = "UTILITY";
        this.language = "pt_BR";
        this.body = "";
        this.intentions = [];
        this.entities = [];
        this.buttons = [];
        this.optOutAllowed = false;
        this.buttonType = this.buttonTypes[0];
      } else {
        window.analytics.track(
          "Novo Template",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
      }
    },
    chatGptSuggestionText() {
      if (this.chatGptSuggestionText === "")
        this.chatGptSuggestionGenerated = false;
    },
  },
  computed: {
    currentAccId() {
      return this.$store.state.acc.current_acc.id;
    },
    accHasAIFeature() {
      return (
        this.$store.state.acc.current_acc.aiEnable ||
        this.currentAccId === "60ad40a8cf24431d122b2433" ||
        this.currentAccId === "608c60d25e9671ec25384820" ||
        this.currentAccId === "5f5a4806e7a7bc318ae589cc" ||
        this.currentAccId === "64e7286b799c8d04217445ee" ||
        this.currentAccId === "626a9da67edc070332477a55" ||
        this.currentAccId === "626a9d947edc07e34c4776a1" ||
        this.currentAccId === "64f774f21bb62e5a0f0ae7be" ||
        this.currentAccId === "634f2828d73776a6886df536" ||
        this.currentAccId === "638ddbce63ef447b55b5f6b1" ||
        this.currentAccId === "63286decb79ebf3b3384367e" ||
        this.currentAccId === "6298bbf57d445a5dec90e060" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "623b367aadbb4d4aa6802372" ||
        this.currentAccId === "63a36fd60a079cd7b75de1ed" ||
        this.currentAccId === "63a36fe60a079c2e6d5de573" ||
        this.currentAccId === "647de082c17f5f6bc518bcca" ||
        this.currentAccId === "647de0c5f88ea8e8edbbd3dd" ||
        this.currentAccId === "647de0ddfdf35443e301ebcf" ||
        this.currentAccId === "647de0f4fdf354294f01ef36" ||
        this.currentAccId === "647de106f88ea8652cbbde82" ||
        this.currentAccId === "647de11ed44b0d4ad326c9d2" ||
        this.currentAccId === "647de131821e83a789e02735" ||
        this.currentAccId === "647de37afdf354c84502809b" ||
        this.currentAccId === "647de399d44b0d36682757c2" ||
        this.currentAccId === "647de3af7c43cdc9d2c25192" ||
        this.currentAccId === "647e12ed2e3fd754b5375ef1" ||
        this.currentAccId === "648c67528f79849e304b2205" ||
        this.currentAccId === "64a5870d3ef4914948a88910" ||
        this.currentAccId === "64a5871e997f109a046f4c4c" ||
        this.currentAccId === "6310a6c603f50d057e202d02" ||
        this.currentAccId === "6310a75f03f50d65af207d68" ||
        this.currentAccId === "63a36faa51d3cd17c1e400a3" ||
        this.currentAccId === "63ab38651b5aadfa16ab87b6" ||
        this.currentAccId === "63efc042b2d31059ad35d66e"
      );
    },
    operatorName() {
      if (this.currentOperator) {
        return this.operators
          .find((op) => op._id === this.currentOperator.user)
          .name.trim();
      }
      return undefined;
    },
    webhookURL: {
      get() {
        if (this.template != null) {
          let url = `${process.env.VUE_APP_API_URL}p/campaign/send`;

          let query = `?chatbot=${this.chatFilter._id}&360Dialog=true&NOME_ATENDENTE=${this.operatorName}`;

          if (
            this.intentionWebhook &&
            this.intentionWebhook.value &&
            this.intentionWebhook.value !== "__other__" &&
            this.intentionWebhook.value !== "__all__"
          ) {
            query += "&intention=" + this.intentionWebhook.value;
          }

          if (this.webhookChannel) query += "&provider=" + this.webhookChannel;
          if (this.webhookChannel !== 7)
            url += `/${this.template._id}/${this.templateID}`;
          if (this.sendToOpenConversations === "1") query += "&sendToOpen=1";
          if (
            this.webhookChannel === 3 &&
            this.webhookChannelName &&
            this.webhookChannelName.length > 0
          )
            query += "&providerName=" + this.webhookChannelName;
          return encodeURI(`${url}${query}`);
        }

        return "";
      },
      set() {},
    },
    headerUrl() {
      if (typeof this.headerMedia === "string") return this.headerMedia;

      if (this.headerType === "DOCUMENT") return;

      if (!this.headerMedia) return;

      return URL.createObjectURL(this.headerMedia);
    },
    headerMimes() {
      switch (this.headerType) {
        case "IMAGE":
          return ".jpg,.jpeg,.png";
        case "VIDEO":
          return ".mp4,.3gpp";
        case "DOCUMENT":
          return ".txt,.pdf,.docx,.doc,.ppt,.pps,.xls,.xslx,.psd,.cdr,.fla,.swf,.dwg";
        default:
          return "*";
      }
    },
    statusStyle() {
      let styles = ["text-white"];
      switch (this.status) {
        case "approved":
          styles.push("bg-success");
          break;
        case "rejected":
          styles.push("bg-danger");
          break;
        default:
          styles.push("bg-dark");
      }

      return styles;
    },
    chatFilter: {
      get() {
        return this.$store.state.chat.whatsappApi360DialogCurrentChat;
      },
      async set(opt) {
        await this.$store.dispatch(
          "chat/setWhatsappApi360DialogCurrentChat",
          opt
        );
        this.load360DialogTemplates();
        this.currentWppTemplate = {
          chat: opt,
          templateName: "",
          templateID: "",
          templateStatus: "Não Submetido",
          templateCategory: "",
          rejectedReason: null,
          isModified: false,
        };
        this.currentWppTemplate.templateName =
          `tp_${this.title}_${this.chatFilter.label}`
            .replace(" ", "_")
            .replace(/[^a-zA-Z_]/g, "")
            .toLowerCase();
      },
    },
    chatOptions() {
      return this.$store.state.chat.chats360;
    },
    whatsappApi360DialogTemplates() {
      const templates = [];
      this.$store.state.chat.whatsappApi360DialogTemplates.forEach(
        (template) => {
          const header = template.components.find(
            (comp) => comp.type === "HEADER"
          );

          templates.push({
            ...template,
            // ternário é usado para dar filtro a tabela
            header: header
              ? {
                  format: header ? header.format : "",
                  withMedia: !!template.headerMediaUrl,
                }
              : "",
          });
        }
      );
      return templates;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    addButton() {
      let buttonLength = 3;

      if (this.buttonType === this.buttonTypes[1]) buttonLength = 2;
      if (this.buttons.length < buttonLength) {
        this.buttons.push({ text: "" });
        this.$nextTick(() => {
          this.$el.scrollTop = this.$el.scrollHeight;
        });
      }
    },
    removeButton(index) {
      this.buttons.splice(index, 1);
    },
    async getOperatorData() {
      const res = await this.$http.get(
        `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
        {
          params: { acc: this.$store.state.acc.current_acc.id },
        }
      );

      const data = res.data.data;

      this.currentOperator = data.operator;
      this.operators = data.operators;
    },
    openWebhook(id) {
      this.templateID = id;
      this.popupWebhookActivate = true;
      this.template = this.$store.state.chat.whatsappApi360DialogTemplates.find(
        (i) => i.id === id
      );
      this.status = this.template.status;
    },
    async previewFile(e) {
      // remove input value
      // (this.$refs.headerInputMedia.value = "")

      if (!e.target.files.length) {
        this.headerMedia = null;
        return;
      }

      if (this.templateID) {
        this.headerMedia = null;
        setTimeout(() => {
          this.headerMedia = e.target.files[0];
        }, 200);
        return;
      }

      this.headerMedia = e.target.files[0];
    },
    copyInputText(elementID) {
      const e = document.getElementById(elementID);
      if (!e)
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      // Select the text
      e.select();
      e.setSelectionRange(0, 99999); // For mobile devices

      navigator.clipboard
        .writeText(e.value)
        .then(() => {
          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("CopiedToClipboard"),
            color: "success",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-check-circle",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("Error"),
            text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    openEdit(id) {
      this.editid = id;
      this.templateID = id;
      this.headerType = "Nenhum";
      const template =
        this.$store.state.chat.whatsappApi360DialogTemplates.find(
          (i) => i.id === id
        );
      template.components.forEach((comp) => {
        if (comp.type === "HEADER") {
          this.headerType = comp.format;
        }
        if (comp.type === "BUTTONS") {
          if ("phone_number" in comp.buttons[0] || "url" in comp.buttons[0]) {
            this.buttonType = this.buttonTypes[1];
          } else {
            this.buttonType = this.buttonTypes[0];
          }

          comp.buttons.forEach((btn) => {
            this.buttons.push({
              text: btn.text,
              action: btn.url || btn.phone_number,
            });
          });
        }
      });

      this.headerMedia = template.headerMediaUrl;

      this.name = template.name;
      this.title = template.title;
      this.status = template.status;
      this.body = template.body;

      const entities = [];
      if (template.entities && template.entities.length) {
        template.entities.forEach((el) => {
          let label = el;
          if (el === "__all__") label = this.$t("All");
          if (el === "__other__") label = this.$t("Outros");
          entities.push({
            label: label,
            value: el,
          });
        });
      } else entities.push({ label: this.$t("All"), value: "__all__" });
      this.entities = entities;

      const intentions = [];
      if (template.intentions && template.intentions.length) {
        template.intentions.forEach((el) => {
          let label = el;
          if (el === "__all__") label = this.$t("All");
          if (el === "__other__") label = this.$t("Outros");
          intentions.push({
            label: label,
            value: el,
          });
        });
      } else intentions.push({ label: this.$t("All"), value: "__all__" });
      this.intentions = intentions;

      this.category = template.category;
      this.language = template.language;
      this.optOutAllowed = template.optOutAllowed;
      this.popupEditActive = true;
    },
    createOrUpdateTemplate() {
      if (
        !this.templateID &&
        (!this.title || !this.body || !this.category || !this.language)
      ) {
        this.$vs.notify({
          time: 5000,
          title: "Por Favor Preencha os campos!",
          text: "Os campos com (*) são necessarios para a criação do template",
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      this.$vs.loading();

      const fd = new FormData();

      if (this.headerType !== "Nenhum" && this.headerMedia) {
        fd.append("content", this.headerMedia);
        fd.append("headerType", this.headerType);
        fd.append("headerMediaName", this.headerMedia.name);
        if (this.templateID && typeof this.headerMedia === "string")
          fd.append("headerMediaUrl", this.headerMedia);
      }

      fd.append("status", this.status);
      fd.append("name", this.name);
      fd.append("title", this.title);
      fd.append("header", this.header);
      fd.append("body", this.body);
      if (this.intentions.length > 0) {
        this.intentions.forEach((item) => {
          if (item.value) fd.append("intentions[]", item.value);
        });
      }
      if (this.entities.length > 0) {
        this.entities.forEach((item) => {
          if (item.value) fd.append("entities[]", item.value);
        });
      }
      fd.append("language", this.language);
      fd.append("category", this.category);
      fd.append("buttons", JSON.stringify(this.buttons));
      fd.append("buttonType", this.buttonType);
      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append(
        "chat",
        this.$store.state.chat.whatsappApi360DialogCurrentChat._id
      );
      fd.append("optOutAllowed", this.optOutAllowed);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}p/chat/inbox/360DialogTemplates${
            this.templateID ? `/${this.templateID}` : ""
          }`,
          fd,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          },
          { timeout: 300000 }
        )
        .then(async (res) => {
          this.$vs.loading.close();

          const { data } = res.data;

          const template = {
            ...data,

            body: data.components
              .map((c) => {
                if (c.type.toLowerCase().includes("body")) {
                  return c.text;
                }
              })
              .toString()
              .replace(/,/g, ""),
          };

          let newTemplates = [...this.whatsappApi360DialogTemplates, template];

          if (this.templateID) {
            newTemplates = this.whatsappApi360DialogTemplates.map((elm) => {
              if (elm.id === template.id) {
                return template;
              }
              return elm;
            });
          }

          await this.$store.dispatch(
            "chat/setWhatsappApi360DialogTemplates",
            newTemplates
          );

          this.$vs.notify({
            time: 4000,
            title: "Sucesso",
            text: "Template Submetido Com Sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
          });

          this.popupActive = false;
          this.popupEditActive = false;
          this.optOutAllowed = false;
          this.templateID = null;
          this.title = "";
          this.status = null;
          this.category = "UTILITY";
          this.language = { label: "Portugues (pt_BR)", value: "pt_BR" };
          this.body = "";

          this.load360DialogTemplates();
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
          this.$vs.loading.close();

          this.$vs.notify({
            time: 4000,
            title: this.$t("Error"),
            text: error.message || this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    deleteTemplate(id) {
      this.$vs.loading();
      /* UnComment below lines for enabling true flow if deleting user */
      this.$http
        .delete(
          `/p/chat/inbox/360DialogTemplates/acc/${this.$store.state.acc.current_acc.id}/chat/${this.$store.state.chat.whatsappApi360DialogCurrentChat._id}/template/${id}`
        )
        .then(async () => {
          this.$vs.loading.close();
          const newTemplates = this.whatsappApi360DialogTemplates.filter(
            (e) => e.id !== id
          );

          await this.$store.dispatch(
            "chat/setWhatsappApi360DialogTemplates",
            newTemplates
          );
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    getFormatedTitle(name, status) {
      return `${name} - ${status}`;
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.intentionsFilter =
          {
            label: "All",
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(normalizeString(val));
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    async resendMediaTemplates() {
      if (!this.$store.state.chat.whatsappApi360DialogCurrentChat) return;
      this.$vs.loading();

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}p/chat/inbox/resend360DialogMediaTemplates/${this.$store.state.acc.current_acc.id}`,
          {
            chat: this.$store.state.chat.whatsappApi360DialogCurrentChat._id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          },
          { timeout: 300000 }
        );

        this.$vs.loading.close();
        await this.load360DialogTemplates();
      } catch (e) {
        this.$vs.notify({
          title: "Resend Media Error",
          text: e.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }

      this.$vs.loading.close();
    },
    async load360DialogTemplates() {
      if (!this.$store.state.chat.whatsappApi360DialogCurrentChat) return;
      this.syncingTemplates = true;
      await this.getOperatorData();
      this.$http
        .post(
          `/p/chat/inbox/load360DialogTemplates/${this.$store.state.acc.current_acc.id}`,
          {
            chat: this.$store.state.chat.whatsappApi360DialogCurrentChat._id,
          }
        )
        .then(async (res) => {
          this.syncingTemplates = false;
          const { data } = res.data;

          const templates = data.map((template) => {
            return {
              ...template,
              header: template.components
                .map((c) => {
                  if (c.type.toLowerCase().includes("header")) {
                    return c.text;
                  }
                })
                .toString()
                .replace(/,/g, ""),
              body: template.components
                .map((c) => {
                  if (c.type.toLowerCase().includes("body")) {
                    return c.text;
                  }
                })
                .toString()
                .replace(/,/g, ""),
            };
          });

          await this.$store.dispatch(
            "chat/setWhatsappApi360DialogTemplates",
            templates
          );
        })
        .catch(() => {
          this.syncingTemplates = false;
        });
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (res) => {
          res.data.data.map(async (chatbot) => {
            if (!chatbot.inboxConfig) return;
            if (chatbot.inboxConfig.wppApi360DialogStatus) {
              if (!this.$store.state.chat.whatsappApi360DialogCurrentChat) {
                this.chatFilter = chatbot;
              }
              this.$store.dispatch("chat/setChatsItem360", chatbot);
            }
          });
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async generateChatGptSuggestion() {
      window.analytics.track(
        "Sugerir Template",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$vs.loading();
      this.chatGptSuggestionGenerated = true;
      let generatedText = "";

      try {
        generatedText = await this.$http.post("/p/chat/ai/generate/message", {
          messages: [
            {
              role: "user",
              content: this.chatGptSuggestionText,
            },
          ],
          acc: this.$store.state.acc.current_acc.id,
          type: "template",
        });
      } catch (err) {
        if (typeof err.request !== "undefined")
          if (typeof err.request.response !== "undefined")
            err.message = JSON.parse(err.request.response).message;
          else err.message = this.$t("UnexpectedErrorLoadX", ["Templates"]);
        this.$vs.notify({
          title: this.$t("UnexpectedError"),
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }

      if (generatedText && generatedText.data && generatedText.data.data)
        this.body = generatedText.data.data;

      this.$vs.loading.close();
    },
    replaceEntitiesIntentions(param) {
      if (!param.value || (param.value && param.value.length === 0)) {
        return this.$t("All");
      } else {
        let localArr = JSON.parse(JSON.stringify(param.value));
        if (Array.isArray(param.value)) {
          let index = param.value.indexOf("__all__");
          if (index > -1) localArr[index] = this.$t("All");
          index = param.value.indexOf("__other__");
          if (index > -1) localArr[index] = this.$t("Others");
        }
        return localArr;
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
    this.load360DialogTemplates();
  },
  created() {
    if (this.$store.state.chat.chats360.length === 0) this.getChats();

    this.$http
      .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      })
      .then((res) => {
        if (res.data.data.inbox.ia) this.ia = res.data.data.inbox.ia;
        const keyEntities = [];
        res.data.data.inbox.keyEntities.forEach((value) => {
          keyEntities.push({ label: value, value });
        });
        this.inboxEntities = keyEntities;
        this.inboxEntities.unshift({
          label: this.$t("All"),
          value: "__all__",
        });

        const intentions = [];
        res.data.data.inbox.intentions.forEach((value) => {
          intentions.push({ label: value, value });
        });
        this.intentionsOptions = [...new Set(intentions)];
        this.intentionsOptions.unshift({
          label: this.$t("All"),
          value: "__all__",
        });
        this.intentionsOptions.push({
          label: this.$t("Others"),
          value: "__other__",
        });
      });
  },
};
</script>

<style lang="scss">
.warning {
  background: rgb(246, 244, 244);
  padding: 5px;
  color: rgb(104, 100, 100);
}
#quick-messages-table {
  max-height: 500px;
}
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

#intentions {
  margin-bottom: 10px;
}

.chatGptSuggestion {
  width: 572px;
  height: 67px;
  border-radius: 6px;
  border: 1px solid #ffd200;
  justify-content: center;
  align-items: center;
  background: #fff;
  display: grid;
  grid-template-columns: 60% 35%;

  .chatGptSuggestionButton {
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #ffd200;
    background: rgba(255, 210, 0, 0.07);
  }
}

.alertWidth {
  width: 260px;
}
</style>
