<template>
  <div>
    <vs-row>
      <vs-col vs-w="12" v-if="!isEditing">
        <vs-input name="email" v-model="createQuickMessages.title" type="email" class="w-full" :label="$t('Title')"/>
      </vs-col>
      <vs-col vs-w="12" class="mt-4">
        <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
          <label class="vs-input--label">{{ $t("Intention") }}</label>
          <vs-select class="w-full" v-model="createQuickMessages.intentions" autocomplete multiple placeholder="Selecione">
            <vs-select-item v-for="(item, itemIndex) in createQuickMessagesOptions.intentions"
                            :value="item.value" :text="item.label" :key="`intention-${itemIndex}`"/>
          </vs-select>
        </div>
      </vs-col>
      <vs-col vs-w="12" class="mt-4" v-if="createQuickMessagesOptions.entities.length > 0">
        <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
          <label class="vs-input--label">{{ $t("KeyEntities") }}</label>
          <vs-select class="w-full" v-model="createQuickMessages.entities" item-value="value" multiple placeholder="Selecione">
            <vs-select-item v-for="(item, itemIndex) in createQuickMessagesOptions.entities" :value="item.value" :text="item.label" :key="`entities-${itemIndex}`"/>
          </vs-select>
        </div>
      </vs-col>
    </vs-row>
    <div class="flex gap-x-5 mt-6">
      <vs-radio v-model="quickMessagePopup.selectedQckType" :vs-value="1" class="duo-custom-radio">Texto e mídia</vs-radio>
      <vs-radio v-model="quickMessagePopup.selectedQckType" :vs-value="2" class="duo-custom-radio">Audio</vs-radio>
    </div>
    <div class="mt-4" v-if="quickMessagePopup.selectedQckType === 1">
      <!--          <label class="vs-select&#45;&#45;label">{{ $t("Media") }}</label>-->
      <vs-button type="border" class="flex items-center" @click="triggerFileInput">
        <vs-icon icon-pack="feather" icon="icon-upload"/>
        Inserir mídia
      </vs-button>
      <input style="display: none;" type="file" @change="quickMessagePreviewFile" ref="inputMedia" accept="*"/>
    </div>
    <div class="mt-4 flex gap-x-5" v-if="quickMessagePopup.selectedQckType === 2">
      <template v-if="!qckMessageAudioSource">
        <vs-button type="border" icon-pack="feather" :icon="!qckMessageRecorder || qckMessageRecorder.state !== 'recording'? 'icon-mic': ''" :class="!qckMessageRecorder || qckMessageRecorder.state !== 'recording'? '': 'animate-pulse'" class="hover:bg-primary hover:text-white" @click="handleRecording()">
      <span class="flex text-9xl items-center" v-if="!qckMessageRecorder">
        Gravar áudio
      </span>
          <span class="flex text-9xl items-center" v-if="qckMessageRecorder">
        <vs-icon class="mr-2" icon="stop" color="red" />
        {{`${quickMessagePopup.mediaAudioRecordingMin}: ${quickMessagePopup.mediaAudioRecordingSec}`}}
      </span>
        </vs-button>
      </template>
      <template v-if="qckMessageAudioSource">
        <div class="w-2/3 flex items-center gap-2">
          <audio controls preload="auto">
            <source :src="urlFormatter" />
          </audio>
          <vs-button icon-pack="feather" icon="icon-trash" radius @click="cleanMessageInput()"/>
        </div>
      </template>
    </div>
    <div class="mt-4" v-if="quickMessageMediaObj && quickMessagePopup.selectedQckType !== 2">
      <video v-if="quickMessageMediaMimetype.includes('video')" controls="controls" width="50%">
        <source :src="quickMessageMediaUrl" />
        <!--Suportado em IE9, Chrome 6 e Safari 5 -->
        O seu navegador não suporta a tag vídeo
      </video>
      <img v-else-if="quickMessageMediaMimetype.includes('image')" :src="quickMessageMediaUrl" width="50%"/>
    </div>
    <template v-if="quickMessagePopup.editing && editMessageDisable">
      <vs-col vs-w="12" class="mt-4">
        <vs-textarea
          name="message"
          v-model="message"
          class="w-full my-3"
          style="cursor: not-allowed"
          rows="10"
          :disabled="editMessageDisable"
          placeholder="Escreva uma mensagem..."
        />
      </vs-col>
      <vs-col vs-w="12" class="mt-4">
        <vs-alert color="warning" class="mt-4">
          Não é possível editar a mensagem de mensagens submetidas como
          template WhatsApp.
        </vs-alert>
      </vs-col>
    </template>
    <template v-else-if="quickMessagePopup.selectedQckType !== 2">
      <vs-col vs-w="12" class="mt-4">
        <vs-textarea
          name="email"
          v-model="createQuickMessages.message"
          class="w-full"
          placeholder="Escreva uma mensagem..."
          rows="5"
        />
        <small v-if="quickMessagePopup.selectedQckType !== 2">
          * Digite {NOME_ATENDENTE} para adicionar o nome do atendente,
          {NOME_CONTATO} para o nome do contato e {ID_CONVERSA} para
          adicionar o ID da conversa.
        </small>
      </vs-col>
    </template>
    <vs-col vs-type="flex" vs-justify="flex-end" class="mt-12 w-full">
      <vs-button class="w-full" icon-pack="feather" icon="icon-save" size="medium" @click="isEditing ? edit(editId) : createQuickMessage()">
        {{ $t("Save") }}
      </vs-button>
    </vs-col>
  </div>
</template>

<script>
import axios from "@/axios";
import OpusMediaRecorder from "OpusMediaRecorder";
import {mapState} from "vuex";

export default {
  name: "createQuickMessagePopup",
  data() {
    return {
      createQuickMessages: {
        title: "",
        intentions: [],
        entities: [],
        message: "",
      },
      quickMessagePopup: {
        editing: false,
        audioUrl: null,
        selectedQckType: null,
        recordingAudio: null,
        mediaAudioInterval: null,
        mediaAudioRecordingMin: "00",
        mediaAudioRecordingSec: "00"
      },
      audio: {
        duration: 0,
        currentTime: 0,
        playing: false,
        speed: 1.0
      },
      quickMessageMediaObj: "",
      createdQuickMessageId: ""
    }
  },
  props: {
    createQuickMessagesOptions: {
      type: Object,
    },
    quickMessageMedia: {
      type: [Object, String],
    },
    quickMessageMediaMimetype: {
      type: String,
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    editId: {
      type: String,
      default: ""
    },
    editType: {
      type: Number,
      default: 1
    },
    editTitle: {
      type: String
    },
    entities: {
      type: Array
    },
    intentions: {
      type: Array
    },
    editMessage: {
      type: String
    },
    editMessageDisable: {
      type: Boolean
    }
  },
  computed: {
    urlFormatter() {
      if (this.editId && this.qckMessageAudioSource && !this.quickMessageMedia) {
        return this.qckMessageAudioSource
      }
      if (!this.editId && this.qckMessageAudioSource) {
        return this.qckMessageAudioSource
      }

      return `${process.env.VUE_APP_API_URL}p/chat/inbox/quickMessages/tempFile/${this.editId}`;
    },
    quickMessageMediaUrl() {
      return typeof this.quickMessageMedia === "string" ? this.quickMessageMedia : URL.createObjectURL(this.quickMessageMedia);
    },
    ...mapState("chat", [
      "qckMessageBlob",
      "qckMessageAudioSource",
      "qckMessageRecorder",
    ]),
    activeContact: {
      get: function () {
        return this.$store.state.chat.activeContact;
      },
      set: async function (data) {
        await this.$store.dispatch("chat/setActiveContact", data);
      },
    },
  },
  watch: {
    entities: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.createQuickMessages.entities = newValue
        }
      },
      immediate: true
    },
    'quickMessagePopup.selectedQckType': {
      handler(newValue) {
        if (newValue === 1)
          this.cleanMessageInput()
      },
      immediate: true
    }
  },
  beforeMount() {
    this.createQuickMessages.title = "";
    this.createQuickMessages.message = "";
    this.createQuickMessages.entities = [];
    this.createQuickMessages.intentions = [];
    this.quickMessagePopup.selectedQckType = this.editType;
    this.createQuickMessages.message = this.editMessage;
    this.quickMessageMediaObj = this.quickMessageMedia;
  },
  mounted() {
    if (this.editTitle) this.createQuickMessages.title = this.editTitle;
    if (this.entities) this.createQuickMessages.entities = this.transformSelectedEntities(this.entities);
    if (this.intentions) this.createQuickMessages.intentions = this.transformSelectedEntities(this.intentions);
    if (this.quickMessageMediaObj && this.quickMessagePopup.selectedQckType === 2) this.$store.dispatch("chat/setQckMessageAudioSource", this.quickMessageMediaObj);
  },
  beforeDestroy() {
    this.createQuickMessages.title = "";
    this.createQuickMessages.message = "";
    this.createQuickMessages.entities = [];
    this.createQuickMessages.intentions = [];
    this.stopRecording()
    this.$store.dispatch("chat/setQckMessageBlob", null);
    this.$store.dispatch("chat/setQckMessageAudioSource", null);
    this.quickMessageMedia = null;
    this.quickMessageMediaObj = "";
    this.createdQuickMessageId = "";
    this.quickMessagePopup.audioUrl = null;
    this.quickMessagePopup.recordingAudio = null;
    clearInterval(this.quickMessagePopup.mediaAudioInterval);
  },
  methods: {
    play() {
      if (this.audio.playing) {
        this.$refs.audio.pause();
      } else {
        this.$refs.audio.play();
      }
      this.audio.playing = !this.audio.playing;
    },
    changeQckType(value) {
      this.cleanMessageInput()
      switch (value) {
        case 1:
          this.quickMessagePopup.selectedQckType = 1;
          break;
        case 2:
          this.quickMessagePopup.selectedQckType = 2;
          break;
      }
    },
    stopRecording() {
      this.$store.state.chat.qckMessageRecorder.stop();
      this.$store.state.chat.qckMessageRecorder.stream
        .getTracks()
        .forEach((i) => i.stop());
      this.$store.dispatch("chat/setQckMessageAudioRecorder", null);
      clearInterval(this.quickMessagePopup.mediaAudioInterval);
    },
    startRecording() {
      const workerOptions = {
        OggOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/codec/OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/codec/WebMOpusEncoder.wasm`,
      };
      window.MediaRecorder = OpusMediaRecorder;
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        let options = { mimeType: "audio/ogg;codecs=opus" };
        // Start recording
        this.$store.dispatch("chat/setQckMessageAudioRecorder", new MediaRecorder(stream, options, workerOptions));
        let startTime = Date.now();
        this.quickMessagePopup.mediaAudioInterval = setInterval(() => {
          let elapsedTime = Date.now() - startTime;
          let minutes = Math.floor(elapsedTime / 60000);
          let seconds = Math.floor((elapsedTime % 60000) / 1000);
          this.quickMessagePopup.mediaAudioRecordingMin = minutes < 10 ? "0" + minutes : minutes;
          this.quickMessagePopup.mediaAudioRecordingSec = seconds < 10 ? "0" + seconds : seconds;
        }, 1000);
        // Set record to <audio> when recording will be finished
        this.$store.state.chat.qckMessageRecorder.addEventListener("dataavailable", (event) => {
            this.$store.dispatch("chat/setQckMessageAudioSource", URL.createObjectURL(event.data));
            this.$store.dispatch("chat/setQckMessageBlob", event.data);
          }
        );
        this.$store.state.chat.qckMessageRecorder.start();
      });
    },
    handleRecording() {
      window.analytics.track(
        "Gravação de audio mensagem rápida",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      !this.$store.state.chat.qckMessageRecorder || this.$store.state.chat.qckMessageRecorder.state !== "recording" ? this.startRecording() : this.stopRecording();
    },
    triggerFileInput() {
      this.$refs.inputMedia.click();
    },
    cleanMessageInput() {
      this.$store.dispatch("chat/setQckMessageBlob", null);
      this.$store.dispatch("chat/setQckMessageAudioSource", null);
      this.quickMessageMedia = null;
      // this.quickMessageMediaObj = "";
      this.quickMessagePopup.audioUrl = null;
      this.quickMessagePopup.recordingAudio = null;
      clearInterval(this.quickMessagePopup.mediaAudioInterval);
      this.quickMessagePopup.mediaAudioRecordingMin = "00";
      this.quickMessagePopup.mediaAudioRecordingSec = "00";
    },
    quickMessagePreviewFile(event) {
      if (!event.target.files.length) {
        this.quickMessageMedia = null;
        this.quickMessageMediaObj = "";
        return;
      }

      this.quickMessageMedia = event.target.files[0];
      this.quickMessageMediaObj = event.target.files[0];

      this.quickMessageMediaMimetype = this.quickMessageMedia.type || "";
    },
    transformSelectedEntities(entities) {
      return entities.map(entity => {
        if (typeof entity === 'object' && entity !== null) {
          return entity.value;
        }
        return entity;
      });
    },
    async edit(editId) {
      const fd = new FormData();

      if (!this.qckMessageAudioSource && this.quickMessagePopup.selectedQckType === 2) {
        this.$vs.notify({
          color: "danger",
          title: "Falha ao editar mensagem rápida.",
          text: "Mensagem rápida não pode ser vazia.",
          position: "top-right",
          time: 4000,
        });
        return
      }
      switch (this.quickMessagePopup.selectedQckType) {
        case 1:
          fd.append("type", "text/media")
          break
        case 2: {
          if (this.qckMessageBlob) {
            const upload = new FormData();
            const audioFileName = this.qckMessageAudioSource.split("/");
            const filename = `${audioFileName[audioFileName.length - 1]}.oga`;
            upload.append("content", this.qckMessageBlob);
            if (filename) upload.append("filename", filename);
            if (this.$store.state.acc.current_acc.id) upload.append("acc", this.$store.state.acc.current_acc.id);

            const audioUrl = await this.$http.post(`/p/chat/saveMessageFile`, upload, {
              headers: {
                Authorization: "Bearer " + this.$store.state.auth.accessToken,
                "Content-Type": "multipart/form-data",
              },
            })
            if (audioUrl && audioUrl.data && audioUrl.data.data) fd.append("audioUrl", audioUrl.data.data);
            fd.append("type", "audio");
            this.createQuickMessages.message = "audio";
          }
          break
        }
      }

      fd.append("title", this.createQuickMessages.title);
      fd.append("message", this.createQuickMessages.message);
      if (this.createQuickMessages.intentions.length > 0) {
        this.createQuickMessages.intentions.forEach((item) => {
          if (item) fd.append("intentions[]", item);
        });
      }
      if (this.createQuickMessages.entities.length > 0) {
        this.createQuickMessages.entities.forEach((item) => {
          if (item) fd.append("entities[]", item);
        });
      }

      try {
        this.$vs.loading();
        await this.$http.put(`/p/chat/inbox/quickMessages/${editId}`, fd, {
          params: { acc: this.$store.state.acc.current_acc.id },
        })

        this.$emit("getQuickMessages");

        this.$vs.loading.close();

        this.$emit("closeEditPopup");
      } catch (e) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("error"),
          text: e,
          position: "top-right",
          time: 4000,
        });
      }

      this.$vs.loading.close();
    },
    async createQuickMessage() {
      const fd = new FormData();

      switch (this.quickMessagePopup.selectedQckType) {
        case 1:
          fd.append("type", "text/media")
          if (this.quickMessageMedia) {
            fd.append("content", this.quickMessageMedia);
          }
          break
        case 2: {
          const upload = new FormData();
          const audioFileName = this.qckMessageAudioSource.split("/");
          const filename = `${audioFileName[audioFileName.length - 1]}.oga`;
          upload.append("content", this.qckMessageBlob);
          if (filename) upload.append("filename", filename);
          if (this.$store.state.acc.current_acc.id) upload.append("acc", this.$store.state.acc.current_acc.id);
          const audioUrl = await this.$http.post(`/p/chat/media/upload`, upload, {
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          if (audioUrl && audioUrl.data && audioUrl.data.data) fd.append("audioUrl", audioUrl.data.data);
          fd.append("type", "audio");
          this.createQuickMessages.message = "audio";
          break
        }
      }
      if (!this.createQuickMessages.message) this.createQuickMessages.message = "media";
      fd.append("title", this.createQuickMessages.title);
      fd.append("message", this.createQuickMessages.message);

      if (this.createQuickMessages.intentions.length > 0) {
        this.createQuickMessages.intentions.forEach((item) => {
          if (item) fd.append("intentions[]", item);
        });
      }
      if (this.createQuickMessages.entities.length > 0) {
        this.createQuickMessages.entities.forEach((item) => {
          if (item) fd.append("entities[]", item);
        });
      }

      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append("createdBy", this.$store.state.user._id);

      try {
        await axios.post("/p/chat/inbox/quickMessages", fd)

        this.$vs.notify({
          time: 2500,
          title: this.$t("Ok"),
          text: this.$t("QuickMessageCreated"),
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });

        this.$emit("getQuickMessages");

        this.createQuickMessages = {
          title: "",
          message: "",
          intentions: [],
          entities: [],
        };
        this.quickMessageMedia = null;
        this.quickMessageMediaMimetype = "";
        this.quickMessagePopup.recordingAudio = null;
        this.cleanMessageInput();
        this.$emit("closeCreatePopup");
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: e,
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda suave */
  display: flex;
}
.progress-bar {
  height: 100%;
  background-color: #7367F0;
  border-radius: 5px;
  transition: width 0.1s;
}
</style>
